import { Spinner } from '../../../../../components/Spinner';
import { getHumanReadableDate } from '../../../../../utils/dates';
import { Badge } from 'reactstrap';

function NewObjectBalancesWidget({ loading, balances, bankAccounts }) {
  return (
    <div className="bo-border-container bg-light rounded w-100 p-0">
      <Spinner visible={loading} size={36} borderRadius={5}>
        <div className="p-2">
          <div className="d-flex justify-content-between">
            <p className="bo-new-object-text-bold">Balances</p>
            <p className="bo-new-object-text">
              {balances.date ? `As of ${getHumanReadableDate(balances.date)}` : '---'}
            </p>
          </div>
          {!balances.date && (
            <div className="d-flex justify-content-center align-items-center p-3">
              <p className="bo-new-object-text-xl-bold">N/A</p>
            </div>
          )}
          {balances.date && (
            <table className="mt-3 w-100">
              <colgroup>
                <col width="31%" />
                <col width="25%" />
                <col width="25%" />
                <col width="19%" />
              </colgroup>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th className="bo-new-object-text-bold ps-2">Current</th>
                  <th className="bo-new-object-text-bold ps-2">Available</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {balances.balances.map(balance => (
                  <tr key={balance.plaidAccountId} className="bo-h-30">
                    <td className="bo-new-object-text text-truncate bo-max-w-1">{balance.name}</td>
                    <td className="bo-new-object-text ps-2 text-nowrap">
                      {balance.current !== null && balance.current !== undefined
                        ? `$${balance.current.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : null}
                    </td>
                    <td className="bo-new-object-text ps-2 text-nowrap">
                      {balance.available !== null && balance.available !== undefined
                        ? `$${balance.available.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : null}
                    </td>
                    <td className="p-0 ps-2 pb-1">
                      {bankAccounts.find(
                        acc => acc.plaidAccountId === balance.plaidAccountId && acc.isMain,
                      ) && (
                        <Badge color="success" className="bo-new-object-inline-badge">
                          Main
                        </Badge>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Spinner>
    </div>
  );
}

export default NewObjectBalancesWidget;
