import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { memo } from 'react';
import { NumericFormat } from 'react-number-format';

function FundingSettings({
  fundingId,
  fundingFreeCreditTransfers,
  fundingFreeDebitTransfers,
  fundingTransferFee,
  fundingCollectionLimit,
  fundingCollectionBalanceThreshold,
  setFunding,
  getFunding,
  setSpinner,
}) {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const saveTransferSettings = async (settingName, value) => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/save_funding_setting`,
      method: 'POST',
      data: JSON.stringify({ fundingId, settingName, value }),
    };

    try {
      await makeRequest(config);

      toast.success('Advance transfer settings updated!');
      getFunding(fundingId);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const changeValue = e =>
    setFunding(prevFunding => ({ ...prevFunding, [e.target.name]: e.target.value }));

  return (
    <>
      <Row className="ms-3 mt-4 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label>Free Credit Transfers:</Label>
        </Col>
        <Col md={2} className="bo-same-line">
          <NumericFormat
            customInput={Input}
            className="bo-input me-4"
            type="text"
            name="freeCreditTransfers"
            id="freeCreditTransfers"
            value={fundingFreeCreditTransfers}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={0}
          />
        </Col>
        <Col md={2} className="bo-same-line px-0">
          <Button
            className="bo-button-inline-link"
            color="link"
            onClick={() => saveTransferSettings('freeCreditTransfers', fundingFreeCreditTransfers)}
          >
            Save
          </Button>
        </Col>
      </Row>
      <Row className="ms-3 mt-2 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label>Free Debit Transfers:</Label>
        </Col>
        <Col md={2} className="bo-same-line">
          <NumericFormat
            customInput={Input}
            className="bo-input me-4"
            type="text"
            name="freeDebitTransfers"
            id="freeDebitTransfers"
            value={fundingFreeDebitTransfers}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={0}
          />
        </Col>
        <Col md={2} className="bo-same-line px-0">
          <Button
            className="bo-button-inline-link"
            color="link"
            onClick={() => saveTransferSettings('freeDebitTransfers', fundingFreeDebitTransfers)}
          >
            Save
          </Button>
        </Col>
      </Row>
      <Row className="ms-3 my-2 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label>Transfer Fee ($):</Label>
        </Col>
        <Col md={2} className="bo-same-line">
          <NumericFormat
            customInput={Input}
            className="bo-input me-4"
            type="text"
            name="transferFee"
            id="transferFee"
            value={fundingTransferFee}
            onChange={changeValue}
            allowNegative={false}
          />
        </Col>
        <Col md={2} className="bo-same-line px-0">
          <Button
            className="bo-button-inline-link"
            color="link"
            onClick={() => saveTransferSettings('transferFee', fundingTransferFee)}
          >
            Save
          </Button>
        </Col>
      </Row>
      <Row className="ms-3 my-2 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label>Collection Limit:</Label>
        </Col>
        <Col md={2} className="bo-same-line">
          <NumericFormat
            customInput={Input}
            className="bo-input me-4"
            type="text"
            name="collectionLimit"
            id="collectionLimit"
            value={fundingCollectionLimit}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={0}
          />
        </Col>
        <Col md={2} className="bo-same-line px-0">
          <Button
            className="bo-button-inline-link"
            color="link"
            onClick={() => saveTransferSettings('collectionLimit', fundingCollectionLimit)}
          >
            Save
          </Button>
        </Col>
      </Row>
      <Row className="ms-3 my-2 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label>Collection Balance Threshold:</Label>
        </Col>
        <Col md={2} className="bo-same-line">
          <NumericFormat
            customInput={Input}
            className="bo-input me-4"
            type="text"
            name="collectionBalanceThreshold"
            id="collectionBalanceThreshold"
            value={fundingCollectionBalanceThreshold}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={0}
          />
        </Col>
        <Col md={2} className="bo-same-line px-0">
          <Button
            className="bo-button-inline-link"
            color="link"
            onClick={() =>
              saveTransferSettings('collectionBalanceThreshold', fundingCollectionBalanceThreshold)
            }
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default memo(FundingSettings);
