import moment from 'moment';
import { Badge } from 'reactstrap';
import { memo, useCallback, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedTable from '../../../../../../components/VirtualizedTable';

function NewObjectAllCyclesSubTab({ profile }) {
  const payrollCycles = useMemo(() => {
    if (
      profile &&
      profile.payroll &&
      profile.payroll.cycles &&
      Object.values(profile.payroll.cycles).length > 0
    ) {
      return Object.values(profile.payroll.cycles).sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate),
      );
    }

    return [];
  }, [profile]);

  const tableColumns = useMemo(
    () => [
      {
        key: 'index',
        header: 'Period',
        width: 80,
      },
      {
        key: 'startDate',
        header: 'From',
        width: 100,
      },
      {
        key: 'endDate',
        header: 'To',
        width: 100,
      },
      {
        key: 'totalAmount',
        header: 'Amount',
        width: 120,
        renderValue: value =>
          value !== null ? (
            <div className="d-flex flex-column w-100 text-end me-2">
              {`${value < 0 ? '-' : ''}$${Math.abs(value).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </div>
          ) : (
            '---'
          ),
      },
      {
        key: 'status',
        header: 'Status',
        width: 100,
      },
      {
        key: 'transactions',
        header: 'Trx Amount',
        width: 90,
        renderValue: value => (
          <div className="d-flex flex-column w-100 text-end">
            {value
              .sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate))
              .map(x => (
                <p key={x.uuid} className="p-0 m-0">
                  {x.amount !== null
                    ? `${x.amount < 0 ? '-' : ''}$${Math.abs(x.amount).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : '---'}
                </p>
              ))}
          </div>
        ),
      },
      {
        key: 'transactions',
        header: 'Trx Date',
        width: 120,
        renderValue: (value, row) => (
          <div className="d-flex flex-column">
            {value
              .sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate))
              .map(x => (
                <div key={x.uuid} className="d-flex gap-2">
                  <p className="p-0 m-0">
                    {x.transactionDate !== null ? x.transactionDate : '---'}
                  </p>
                  {row.endDate && moment(row.endDate).isBefore(x.transactionDate) && (
                    <Badge className="bo-new-object-inline-badge bg-warning">Late</Badge>
                  )}
                </div>
              ))}
          </div>
        ),
      },
    ],
    [],
  );

  const getRowHeight = useCallback(
    index => {
      const transactionsLength = payrollCycles[index].transactions?.length || 0;

      return Math.max(transactionsLength * 18 + 18, 36);
    },
    [payrollCycles],
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VirtualizedTable
          tableRows={payrollCycles}
          tableColumns={tableColumns}
          width={width}
          height={height}
          getRowHeight={getRowHeight}
          rowKey="index"
        />
      )}
    </AutoSizer>
  );
}

export default memo(NewObjectAllCyclesSubTab);
