import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// API
import { toast } from 'react-toastify';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuth } from '../../hooks/useAuth';
import { makeRequest } from '../../utils/makeRequest';
// contexts
import { useEnv } from '../../context/env-context';
import { useClockContext } from '../../context/clock-context';
// components
import { Container, Row } from 'reactstrap';
import Loading from '../../components/Loading';
import { Spinner } from '../../components/Spinner';
import FundingTabController from './components/FundingTabController';
import FundingFormController from './components/FundingFormController';
import FundingRepaymentModal from './components/modals/FundingRepaymentModal';
import FundingRepaymentDateModal from './components/modals/FundingRepaymentDateModal';
import FundingPaymentLinkModal from './components/modals/FundingPaymentLinkModal';

function Funding() {
  const params = useParams();
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth();
  const { apiOriginConsumer, apiOriginFunding, apiOriginMonitor } = useEnv();

  const { setConsumerLocation, setConsumerTimeZone } = useClockContext();

  const [spinner, setSpinner] = useState(false);

  const [consumer, setConsumer] = useState(null);
  const [funding, setFunding] = useState(null);
  const [astraUser, setAstraUser] = useState(null);
  const [prediction, setPrediction] = useState(null);
  const [bridgePointsBalance, setBridgePointsBalance] = useState(null);
  const [totalExpectedBalance, setTotalExpectedBalance] = useState(null);

  const [paymentLinksModal, setPaymentLinksModal] = useState(false);
  const [repaymentModal, setRepaymentModal] = useState({ open: false, topup: false });
  const [repaymentDateModal, setRepaymentDateModal] = useState({
    open: false,
    reset: false,
    latestDate: false,
  });

  useEffect(
    () => () => {
      setConsumerLocation({ city: null, state: null, country: 'US' });
      setConsumerTimeZone(null);
    },
    [setConsumerLocation, setConsumerTimeZone],
  );

  const getConsumer = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/get/${id}`,
        method: 'GET',
      };

      try {
        const response = await makeRequest(config);

        setConsumer(response);

        if (response && response.city && response.state) {
          setConsumerLocation(prev => ({ ...prev, city: response.city, state: response.state }));
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [getAccessTokenSilently, apiOriginConsumer, setConsumerLocation],
  );

  const getPrediction = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/get_prediction_admin`,
        method: 'GET',
        params: { consumerId: id },
      };

      try {
        const response = await makeRequest(config);

        setPrediction(response);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [getAccessTokenSilently, apiOriginMonitor],
  );

  const getBridgePointsBalance = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/bridgepoints/balance`,
        method: 'GET',
        params: {
          consumerId: id,
        },
      };

      try {
        const balance = await makeRequest(config);

        setBridgePointsBalance(balance);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [getAccessTokenSilently, apiOriginConsumer],
  );

  const getAstraUser = useCallback(
    async consumerId => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/astra/user`,
        method: 'GET',
        params: { consumerId },
      };

      try {
        const response = await makeRequest(config);

        setAstraUser(response);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [getAccessTokenSilently, apiOriginConsumer],
  );

  const getFunding = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/get/${id}`,
        method: 'GET',
      };

      try {
        const response = await makeRequest(config);

        await getConsumer(response.consumerId);
        await getPrediction(response.consumerId);
        await getBridgePointsBalance(response.consumerId);
        await getAstraUser(response.consumerId);

        setFunding(response);
      } catch (error) {
        if (error.message === `Funding ${id} not found!`) {
          navigate('/fundings');
          toast.error(`Advance ID ${id} not found!`);
        } else {
          toast.error(error.message);
        }
      }
    },
    [
      getAccessTokenSilently,
      apiOriginFunding,
      getConsumer,
      getPrediction,
      getBridgePointsBalance,
      getAstraUser,
      navigate,
    ],
  );

  useEffect(() => {
    getFunding(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openPaymentLinksModal = useCallback(() => setPaymentLinksModal(true), []);
  const closePaymentLinksModal = useCallback(() => setPaymentLinksModal(false), []);

  const openRepaymentModal = useCallback(
    ({ topup }) => setRepaymentModal({ open: true, topup }),
    [],
  );
  const closeRepaymentModal = useCallback(
    () => setRepaymentModal({ open: false, topup: false }),
    [],
  );

  const openRepaymentDateModal = useCallback(
    ({ reset, latestDate }) => setRepaymentDateModal({ open: true, reset, latestDate }),
    [],
  );
  const closeRepaymentDateModal = useCallback(
    () => setRepaymentDateModal({ open: false, reset: false, latestDate: false }),
    [],
  );

  if (!funding) {
    return <Spinner visible text="" />;
  }

  return (
    <>
      <Spinner visible={spinner} text="">
        <Container className="mb-5">
          <Row className="mb-3">
            <h2>Advance</h2>
          </Row>
          <FundingFormController
            funding={funding}
            bridgePointsBalance={bridgePointsBalance}
            totalExpectedBalance={totalExpectedBalance}
            setSpinner={setSpinner}
            getFunding={getFunding}
            openRepaymentModal={openRepaymentModal}
            openRepaymentDateModal={openRepaymentDateModal}
          />
          <FundingTabController
            funding={funding}
            setFunding={setFunding}
            setSpinner={setSpinner}
            consumer={consumer}
            getFunding={getFunding}
            setTotalExpectedBalance={setTotalExpectedBalance}
            openPaymentLinksModal={openPaymentLinksModal}
          />
        </Container>
      </Spinner>
      <FundingPaymentLinkModal
        isOpen={paymentLinksModal}
        close={closePaymentLinksModal}
        funding={funding}
        getFunding={getFunding}
      />
      <FundingRepaymentModal
        isOpen={repaymentModal.open}
        topup={repaymentModal.topup}
        consumer={consumer}
        astraUser={astraUser}
        prediction={prediction}
        close={closeRepaymentModal}
        funding={funding}
        getFunding={getFunding}
      />
      <FundingRepaymentDateModal
        repaymentDateModal={repaymentDateModal}
        close={closeRepaymentDateModal}
        funding={funding}
        getFunding={getFunding}
        setSpinner={setSpinner}
      />
    </>
  );
}

export default withAuthenticationRequired(Funding, { onRedirecting: () => <Loading /> });
